.character-creation-form-label {
  display: block;
  margin-bottom: 1rem;
}

.character-creation-base-text-input {
  width: 90%;
  padding: 12px 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  font-family: "Roboto Slab", sans-serif;
  line-height: 1.5;
}

.character-creation-form-input-text-style {
  width: 90%;
  padding: 12px 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  font-family: "Roboto Slab", sans-serif;
  line-height: 1.5;
}

.character-creation-form-subtitle {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.character-creation-form-textarea {
  height: 150px;
  overflow-y: auto; /* updated from "overflow: vertical" */
  transition: border-color 0.3s;
  width: 90%;
  padding: 12px 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  font-family: "Roboto Slab", sans-serif;
  line-height: 1.5;
  word-wrap: break-word; /* updated from "word-wrap: normal" */
  vertical-align: text-top;
}

