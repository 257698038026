/* Wizard.module.css */
.container {
  max-width: 800px;
  margin: 10px auto;
  background-color: rgb(249, 245, 242);
  border-radius: 10px;
  padding: 10px 30px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  font-family: "Roboto Slab", Baskerville, "Times New Roman";

  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 90%;
}

.label {
  margin-bottom: 0.5rem;
}

.input,
.textarea,
select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea {
  height: 150px;
  overflow-y: auto; /* updated from "overflow: vertical" */
  transition: border-color 0.3s;
  padding: 12px 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 14px;
  font-family: "Roboto Slab", sans-serif;
  line-height: 1.5;
  word-wrap: break-word; /* updated from "word-wrap: normal" */
  vertical-align: text-top;
}

select {
  height: auto;
  min-height: 50px;
}

.button {
  font-family: "Roboto Slab", Baskerville, "Times New Roman";
  font-weight: bold;
  text-transform: none;
  margin-top: 16px;
  background-color: rgb(166, 90, 56);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.ideaContainer {
  cursor: pointer;
  padding: 10px;
}

.selectedIdea {
  background-color: #dedede;
  border: 1px solid #000;
  border-radius: 16px;
}
