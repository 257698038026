/* ResultsList.css */
.results-container {
    position: relative;
  }
  
  .results-list {
    list-style-type: none;
    padding: 0;
  }
  
  .results-button {
    display: block;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    text-align: left;
    width: 100%;
  }
  
  .results-button:hover {
    background-color: #0056b3;
  }
  
  .flyout-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color: white;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
  }
  
  .close-flyout {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  