/* LandingPage.css */

.landingPage {
    font-family: 'Roboto Slab', sans-serif;
}

header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header h1 {
    font-size: 2em;
}

nav ul {
    list-style: none;
    display: flex;
    gap: 20px;
}

nav ul li {
    cursor: pointer;
}

section {
    padding: 50px 20px;
    text-align: center;
}

.welcomeSection {
    background-color: rgb(249, 245, 242);
    color: #1E1C1B;
}

.aboutSection {
    background-color: #1E1C1B;
    color: rgb(249, 245, 242);
}

.gifSection {
    background-color: rgb(249, 245, 242);
    color: #1E1C1B;
}

button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

footer {
    padding: 20px;
    text-align: center;
}
