body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* main.css */

:root {
  --light-purple: #a78bfa;
  --dark-purple: #7c3aed;
  --light-grey: #d1d5db;
  --dark-grey: #1f2937;
  --white: #ffffff;
  --black: #000000;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Light Theme */
.light-theme {
  --bg-color: var(--white);
  --text-color: var(--black);
  --primary-color: var(--light-purple);
  --secondary-color: var(--light-grey);
}

/* Dark Theme */
.dark-theme {
  --bg-color: var(--dark-grey);
  --text-color: var(--white);
  --primary-color: var(--dark-purple);
  --secondary-color: var(--light-grey);
}

body, .TextProcessor {
  background-color: var(--bg-color);
  color: var(--text-color);
}

button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: var(--text-color);
  cursor: pointer;
  padding: 10px 15px;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

button:hover {
  background-color: var(--dark-purple);
}

button[type="button"] {
  background-color: transparent;
  color: var(--text-color);
  font-size: 14px;
  padding: 2px 5px;
}

button[type="button"]:hover {
  background-color: var(--secondary-color);
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  max-width: 500px;
  padding: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

textarea {
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 150px;
  padding: 10px;
  resize: vertical;
  width: 100%;
}

textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

select {
  background-color: var(--bg-color);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--text-color);
  display: block;
  padding: 10px;
  width: 100%;
}

select:focus {
  border-color: var(--primary-color);
  outline: none;
}

.draggable-item {
  align-items: center;
  background-color: var(--bg-color);
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
}

.draggable-item:focus {
  border-color: var(--primary-color);
}

@media (max-width: 768px) {
  form {
    padding: 10px;
  }

  textarea {
    min-height: 100px;
  }
}

