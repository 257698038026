.dropdown-select {
    appearance: none;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    color: #495057;
    font-size: 1rem;
    padding: 0.375rem 2rem 0.375rem 0.75rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }
  
  .dropdown-select:focus {
    outline: 0;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  